import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";

import { FirebaseContext } from "../components/Firebase";
import {
  ContentBg,
  ContentBox,
  Button,
  ErrorMessage,
  Form,
  Input,
  LoginWithGoogle,
} from "../components/ui";

const Register = () => {
  const { firebase, user } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState("");

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
  });
  const intl = useIntl();

  let isMounted = true;

  if (user) {
    navigate("/profile");
  }

  useEffect(() => {
    return () => {
      isMounted = false;
      if (user) {
        navigate("/");
      }
    };
  }, []);

  function handleInputChange(e) {
    e.persist();
    setErrorMessage("");
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (formValues.password === formValues.confirmPassword) {
      firebase
        .register({
          username: formValues.username,
          email: formValues.email,
          password: formValues.password,
        })
        .then(() => navigate("/profile"))
        .catch(error => {
          if (isMounted) {
            if (
              error.message === "Firebase: Error (auth/email-already-in-use)."
            ) {
              setErrorMessage("Account bestaat al. Wachtwoord vergeten?");
            } else {
              setErrorMessage(error.message);
            }
          }
        });
    } else {
      setErrorMessage("Password and Confirm Password fields must match");
    }
  }
  const loginWithGoogle = e => {
    e.preventDefault();
    firebase
      .signInWithGoogle()
      .then(() => navigate("/profile"))
      .catch(error => {
        setErrorMessage(error.message);
      });
  };

  return (
    <ContentBg>
      <ContentBox>
        <h2>
          <FormattedMessage id="register" />
        </h2>
        <Form onSubmit={handleSubmit}>
          <p>
            <strong>
              <FormattedMessage id="quick_easy" />
            </strong>
          </p>
          <LoginWithGoogle onClick={loginWithGoogle} />
          <p>
            <strong>
              <FormattedMessage id="create_account_manually" />:
            </strong>
          </p>
          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Input
            onChange={handleInputChange}
            value={formValues.username}
            placeholder={intl.formatMessage({ id: "name" })}
            type="text"
            required
            name="username"
          />
          <Input
            onChange={handleInputChange}
            value={formValues.email}
            placeholder={intl.formatMessage({ id: "email" })}
            type="email"
            required
            name="email"
          />
          <Input
            onChange={handleInputChange}
            value={formValues.password}
            placeholder={intl.formatMessage({ id: "password" })}
            type="password"
            required
            minLength={6}
            name="password"
          />
          <Input
            onChange={handleInputChange}
            value={formValues.confirmPassword}
            placeholder={intl.formatMessage({ id: "password_confirm" })}
            type="password"
            required
            minLength={6}
            name="confirmPassword"
          />
          <Button type="submit" block>
            <FormattedMessage id="register" />
          </Button>
        </Form>
        <hr />
        <p>
          <FormattedMessage id="already_have_account" />{" "}
          <Link to="/login  ">
            <FormattedMessage id="click_here_to_login" />
          </Link>
          .
        </p>
      </ContentBox>
    </ContentBg>
  );
};

export default Register;
